import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65')
];

export const server_loads = [0,12,2,7,9,3];

export const dictionary = {
		"/": [~13],
		"/admin": [57,[12]],
		"/admin/crawl": [~58,[12]],
		"/admin/crawl/[job]": [~59,[12]],
		"/admin/demos": [~60,[12]],
		"/admin/demos/[id]": [~61,[12]],
		"/admin/organisations": [~62,[12]],
		"/admin/organisations/[id]": [~63,[12]],
		"/admin/waitlist": [~64,[12]],
		"/(public)/auth/fail": [51,[11]],
		"/(public)/auth/verify": [52,[11]],
		"/(public)/contact": [53,[11]],
		"/(public)/demos/[id]": [~54,[11]],
		"/integrations/storyblok/authorize": [~65],
		"/(public)/login": [~55,[11]],
		"/(public)/logout": [56,[11]],
		"/(protected)/me": [~37,[2,7]],
		"/(protected)/me/invite": [~38,[2,7]],
		"/(protected)/me/invite/[inviteId]": [~39,[2,7]],
		"/(protected)/me/new": [~40],
		"/(protected)/me/organisations": [~41,[2,7]],
		"/(protected)/me/organisations/[orga]": [~42,[2,7,9]],
		"/(protected)/me/organisations/[orga]/billing": [~43,[2,7,9]],
		"/(protected)/me/organisations/[orga]/billing/change": [~44,[2,7,9]],
		"/(protected)/me/organisations/[orga]/billing/quote": [~45,[2,7,9]],
		"/(protected)/me/organisations/[orga]/billing/success": [~46,[2,7,9]],
		"/(protected)/me/organisations/[orga]/collaborators": [~47,[2,7,9]],
		"/(protected)/me/organisations/[orga]/invoices": [~48,[2,7,9]],
		"/(protected)/me/settings": [49,[2,7,10]],
		"/(protected)/me/settings/notifications": [~50,[2,7,10]],
		"/(protected)/[agent]": [~14,[2,3],[,4]],
		"/(protected)/[agent]/audiences": [~15,[2,3],[,4]],
		"/(protected)/[agent]/changes": [~16,[2,3],[,4]],
		"/(protected)/[agent]/cohorts": [~17,[2,3],[,4]],
		"/(protected)/[agent]/cohorts/[id]": [~18,[2,3],[,4]],
		"/(protected)/[agent]/explorers": [~19,[2,3],[,4]],
		"/(protected)/[agent]/explorers/[id]": [~20,[2,3],[,4]],
		"/(protected)/[agent]/goals": [~21,[2,3],[,4]],
		"/(protected)/[agent]/insights": [~22,[2,3],[,4]],
		"/(protected)/[agent]/keywords": [~23,[2,3],[,4]],
		"/(protected)/[agent]/pages": [~24,[2,3],[,4]],
		"/(protected)/[agent]/pages/[id]": [~25,[2,3],[,4]],
		"/(protected)/[agent]/personas": [~26,[2,3],[,4]],
		"/(protected)/[agent]/settings": [27,[2,3,5],[,4]],
		"/(protected)/[agent]/settings/danger": [~28,[2,3,5],[,4]],
		"/(protected)/[agent]/settings/guides": [~29,[2,3,5],[,4]],
		"/(protected)/[agent]/settings/notifications": [~30,[2,3,5],[,4]],
		"/(protected)/[agent]/settings/setup": [~31,[2,3,5],[,4]],
		"/(protected)/[agent]/strategies": [~32,[2,3],[,4]],
		"/(protected)/[agent]/tests": [~33,[2,3],[,4]],
		"/(protected)/[agent]/tests/[id]": [~34,[2,3],[,4]],
		"/(protected)/[agent]/welcome": [~35,[2,3],[,4]],
		"/(protected)/[agent]/welcome/[step]": [~36,[2,3,6],[,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';